<template>
  <div>
    <div class="row mt-4 mx-1 justify-content-center">
      <v-container v-if="progress" type="image" class="loding">
        <v-skeleton-loader type="image"></v-skeleton-loader>
      </v-container>
      <div v-else class="col-lg-8 col-sm-12 col-md-10 borderPointier">
        <div class="background-white">
          <div class="title">
            <div class="position">{{ $t("INSTALLATEUR") }} </div>
          </div>
        </div>

        <form class="my-4">
          <div class="center">
            <b-form-group
              :label="$t('QUESTION_SOUE-TRAITANT')"
              class="form-input custom-placeholder legend mt-3"
            >
              <div
                class="d-flex align-items-center form-input custom-placeholder custom-input mb-3"
              >
                <b-form-radio
                  v-model="selected"
                  class="mb-3"
                  name="some-radios"
                  value="A"
                >
                  {{ $t("YES") }}
                </b-form-radio>
                <b-form-radio
                  v-model="selected"
                  class="mb-3"
                  name="some-radios"
                  value="B"
                >
                  {{ $t("NO") }}
                </b-form-radio>
              </div>
            </b-form-group>
        
            <div>
        
              <b-form-group :label="$t('SOUS-TRAITANT')"
              label-for="sous_traitant"
              class="my-4 index" v-if="selected === 'B'">
                <multiselect
                  v-model="selectedSousTraitantData"
                  :searchable="false"
                  :close-on-select="true"
                  :multiple="false"
                  :options="getAllsousTraitant"
                  label="name"
                  track-by="id"
                  :placeholder="$t('SELECTED_SOUE-TRAITANT')"
                  class="form-input custom-placeholder custom-input"
                />
              </b-form-group>

              <b-form-group
                :label="$t('NAME')"
                label-for="name"
                v-if="selected === 'B'"
              >
                <b-form-input
                  id="name"
                  :placeholder="$t('NAME')"
                  class="form-input custom-placeholder custom-input"
                  v-model="selectedSousTraitant.name"
                  oninput="this.value = this.value.toUpperCase()"
                  aria-describedby="name-feedback"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                :label="$t('SIRET')"
                label-for="num_siret"
                v-if="selected === 'B'"
              >
                <b-form-input
                  id="num_siret"
                  class="form-input custom-placeholder custom-input"
                  v-model="selectedSousTraitant.num_siret"
                  :placeholder="$t('SIRET')"
                  oninput="this.value = this.value.toUpperCase()"
                  aria-describedby="num_siret-feedback"
                ></b-form-input>
              </b-form-group>
              <b-form-group
              :label="$t('RGE')"
              label-for="num_rge"
              class="my-4index"
            >
      
              <multiselect
                v-model="selectedCertificat"
                :searchable="true"
                :close-on-select="true"
                :multiple="false"
                :options="getAllCertificatRges"
                label="num"
                track-by="id"
                :placeholder="$t('Sélectionne RGE')"
                class="form-input custom-placeholder custom-input"
              />
            </b-form-group>
         
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="d-flex justify-content-end mt-4">
      <b-button @click="goToPrevStep" class="button-pre-style m-3">
        {{$t("PREVIOUS")}}
      </b-button>
      <b-button
        type="submit"
        @click="goToNextStep"
        class="button-suivant-style m-3"
      >
       {{$t("NEXT")}}
       <div class="loading ml-2" v-if="isLoading">
        <div class="spinner-border spinner-border-sm" role="status"></div>
      </div>
        <div class="loading ml-2" v-if="isLoadingButton">
          <div class="spinner-border spinner-border-sm" role="status"></div>
        </div>
      </b-button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  props: {
    isLoadingButton: {
      type: Boolean,
      default: false,
    },
    progress: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading:false,
      certificat_rge: null,
      selectedSousTraitant: null,
      sousTraitantAdd: false,
      selectedvalue: false,
      selected: null,
    };
  },

  computed: {
    ...mapGetters([
      "getOnlineUser",
      "getdevisMonoFiche",
      "getAllsousTraitant",
      "getAllCertificatRges",
    ]),

    selectedCertificat: {
      get() {
        if (
          typeof this.certificat_rge === "object" &&
          this.certificat_rge !== null
        ) {
       
          return this.certificat_rge;
        } else {
          return this.getAllCertificatRges.find(
            (option) => option.id === this.certificat_rge
          );
        }
      },
      set(value) {
      
        this.certificat_rge = value ? value.id : null;
        this.getdevisMonoFiche.data.devis.certificat_rge_id = value
          ? value.id
          : null;
        this.getdevisMonoFiche.data.devis.certificat_rge = {
          id: value.id,
          num: value.num,
          entreprise:value.entreprise,
          sous_traitant:value.sous_traitant,
          
     
        };
        this.setDevisMonoFiche(this.getdevisMonoFiche);
      },
    },

    selectedSousTraitantData: {
      get() {
        if (
          typeof this.selectedSousTraitant === "object" &&
          this.selectedSousTraitant !== null
        ) {
          return this.selectedSousTraitant;
        } else {
          return this.getAllsousTraitant.find(
            (option) => option.id === this.selectedSousTraitant
          );
        }
      },
      set(value) {
     
        this.selectedCertificat={
          id:null,
          num:""
        };
        if (value && Object.keys(value).length > 0) {
          this.selectedSousTraitant = { ...value };
          this.getdevisMonoFiche.data.groupeLigneDocument[0].sous_traitant = {
            ...value,
          };
          this.getdevisMonoFiche.data.groupeLigneDocument[0].sous_traitant_id =
            value.id;
          this.selectedvalue = true;
        
        } else if(!value){
          this.selectedvalue = false;
          this.selectedSousTraitantData = { id: null,
        name:  "",
        num_siret:  "",}
        this.selectedCertificat={
          id:null,
          num:""
        }
        }
      },
    },
  },
  watch: {
    selectedSousTraitantData(newVal) {
 
      this.fetchData();
      this.selectedvalue = !!newVal;
    },

    selected: {
      immediate: true,
      handler() {
        this.fetchData();
      
        if(this.selected==="A"&& !this.selectedCertificat?.entreprise){
          this.selectedCertificat={
            num:""
          }
        }
       
        if(this.selected==="B"&& !this.selectedCertificat.sous_traitant){
          this.selectedCertificat={
            num:""
          }
        }
      },
    },

    getdevisMonoFiche: {
      handler(newDevis) {
        if (newDevis && newDevis.data) {
          this.selectedSousTraitant = this.initializeSousTraitant();
          this.sousTraitantAdd =
            newDevis.data?.groupeLigneDocument[0]?.sous_traitant === null;
          this.certificat_rge = newDevis.data?.devis?.certificat_rge;
          // this.selectedCertificat=this.certificat_rge
        }
      },
      deep: true,
      immediate: true,
    },
  },

  created() {
    this.allsousTraitant({ search: "",entreprise:this.getdevisMonoFiche.data.devis.entreprise });
    if (this.getdevisMonoFiche && this.getdevisMonoFiche.data) {
      this.selectedSousTraitant = this.initializeSousTraitant();
      this.fetchData();
      this.selected =
        this.getdevisMonoFiche?.data?.groupeLigneDocument[0]?.sous_traitant ===
        null
          ? "A"
          : "B";
    }
  },

  methods: {
    ...mapActions([
      "store_sousTraitant",
      "allsousTraitant",
      "allCertificatRge",
      "sousTraitant",
      "edit_sousTraitant",
      "update_devis_group_BAR",
      "setDevisMonoFiche",
    ]),

    initializeSousTraitant() {
      const sousTraitant =
        this.getdevisMonoFiche?.data?.groupeLigneDocument[0]?.sous_traitant ||
        {};
      return {
        id: sousTraitant.id || null,
        name: sousTraitant.name || "",
        num_siret: sousTraitant.num_siret || "",
      };
    },

    async fetchData() {
      
        if (this.selected === "A") {
          await this.allCertificatRge({
            page: 1,
            per_page: 10,
            search: "",
            sous_traitant_id: "",
            entreprise_id: this.getdevisMonoFiche.data.devis.entreprise.id,
          });
        } else if (this.selected === "B") {
        
          // const sousTraitantId = this.selectedvalue
          //   ? ""
          //   : this.selectedSousTraitant.id;
        
            if( this.selectedSousTraitant.id){
              await this.allCertificatRge({
            page: 1,
            per_page: 10,
            search: "",
            sous_traitant_id: this.selectedSousTraitant.id,
            entreprise_id: "",
            
          });
            }
            else{
           
              await this.allCertificatRge({
            page: 1,
            per_page: 0,
            search: "",
            sous_traitant_id: "",
            entreprise_id: "",
            
          });
            }
         
        }
      
    },

    async edit() {

        if (this.selected === "A") {
          const result = [
            {
              id: this.getdevisMonoFiche.data.groupeLigneDocument[0].id,
              sous_traitant_id: null,
            },
          ];
          await this.update_devis_group_BAR({ result });
          this.getdevisMonoFiche.data.groupeLigneDocument[0].sous_traitant_id =
            null;
          this.getdevisMonoFiche.data.groupeLigneDocument[0].sous_traitant =
            null;
          this.setDevisMonoFiche(this.getdevisMonoFiche);
        } else if (
          this.selected === "B" &&
          this.selectedSousTraitant.id !== null
        ) {
          if (this.selectedSousTraitant.id !== null) {
            const soustrait = {
              ...this.selectedSousTraitant,
              id: this.getdevisMonoFiche.data.groupeLigneDocument[0]
                .sous_traitant.id,
            };
            await this.edit_sousTraitant(soustrait);
            this.setDevisMonoFiche(this.getdevisMonoFiche);
            const result = [
              {
                id: this.getdevisMonoFiche.data.groupeLigneDocument[0].id,
                sous_traitant_id: this.selectedSousTraitant.id,
              },
            ];
            await this.update_devis_group_BAR({ result });
            this.setDevisMonoFiche(this.getdevisMonoFiche);
          } 
          else {
            await this.createSousTraitant();
            this.setDevisMonoFiche(this.getdevisMonoFiche);
          }
        }else {
          await this.createSousTraitant();
   
          this.setDevisMonoFiche(this.getdevisMonoFiche);
        }
     
    },

    async createSousTraitant() {
  
        const data = {
          name: this.selectedSousTraitant.name,
          num_siret: this.selectedSousTraitant.num_siret,
       
        };
        const res = await this.store_sousTraitant(data);

        this.getdevisMonoFiche.data.groupeLigneDocument[0].sous_traitant_id =
          res.data.id;
        this.getdevisMonoFiche.data.groupeLigneDocument[0].sous_traitant = {
          id: res.data.id,
          name: this.selectedSousTraitant.name,
          num_siret: this.selectedSousTraitant.num_siret,
        };
        const result = [
          {
            id: this.getdevisMonoFiche.data.groupeLigneDocument[0].id,
            sous_traitant_id: res.data.id,
          },
        ];
        await this.update_devis_group_BAR({ result });

        this.setDevisMonoFiche(this.getdevisMonoFiche);
     
    },

    async goToNextStep() {
      
      this.isLoading=true;
      if(this.selected==="B"&&this.selectedSousTraitant.name=="" &&this.selectedSousTraitant.num_siret==""){
        
            this.isLoading=false;
            this.$emit("changeStep", 4, true);
          } 
      await this.edit();
      this.isLoading=false;
      this.$emit("changeStep", 4, true);
      
    },

    goToPrevStep() {
      this.$emit("changeStepPrev", 2);
    },
  },
};
</script>

<style scoped>
.borderPointier {
  border: 2px dashed #24316d;
  position: relative;
  margin-bottom: 25px;
  height: 60%;
}
.center .legend {
  font-size: 16px;
}
.background-white {
  display: inline-block;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  position: absolute;
  top: -1rem;
  left: 0;
  right: 0;
  z-index: 1;
  text-align: center;
}
.custom-placeholder::placeholder {
  font-size: 13px;
}

.form-input {
  font-size: 13px;
  height: 41.1px !important;
}
.title {
  color: #24316d;
  position: relative;
  z-index: 2;
  margin: 0;
}
.custom-input {
  box-shadow: none;
}
.position {
  background-color: white;
  display: inline-block;
  padding: 0.05rem 2rem;
}
.index {
  z-index: 20 !important;
}
.small-spinner .spinner-border {
  width: 1rem;
  height: 1rem;
}
</style>
