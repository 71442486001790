<template>
  <div>
    <div class="mt-5">
      <v-container v-if="isLoading" type="image" class="loding">
        <v-skeleton-loader type="image"></v-skeleton-loader>
        <v-skeleton-loader type="image"></v-skeleton-loader>
      </v-container>
      <div v-else class="content">
        <v-stepper v-model="step" alt-labels>
          <v-stepper-header>
            <template v-for="(step, index) in steps">
              <v-stepper-step
                :complete="step > index + 1"
                :step="index + 1"
                :key="'step' + index"
              >
                {{ step.label }}
              </v-stepper-step>
              <v-divider
                v-if="index < steps.length - 1"
                :key="'divider' + index"
              ></v-divider>
            </template>
          </v-stepper-header>

          <div class="stepper-content">
            <component
              v-if="devisObjectLoaded"
              :operation="operation"
              :is="currentStepComponent"
              :progress="progress"
              :produitIds="produitIds"
              :isLoadingButton="isLoadingButton"
              @changeStepPrev="handleStepChangePrv"
              @changeStep="handleStepChange"
            ></component>
          </div>
        </v-stepper>

        <div class="justify-content-end">
          <v-snackbar
            :timeout="2000"
            v-model="Valid"
            color="blue-grey"
            rounded="pill"
          >
            &#x2705; Modification appliquée avec succès.
          </v-snackbar>
        </div>
        <div class="justify-content-end">
          <v-snackbar
            :timeout="2000"
            v-model="Err"
            color="blue-grey"
            rounded="pill"
          >
            &#x274C; Vérifiez la date de fin, elle doit être supérieure ou égale
            à la date de début.
          </v-snackbar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      Err: false,
      Valid: true,
      isLoading: true,
      progress: false,
      isLoadingButton: false,
      step: 1,
      produitIds: [],
      devisObjectLoaded: false,
    };
  },
  props: {
    operation:{
      type:Boolean,
      default: false,
    },
    steps: {
      type: Array,
      required: true,
    },
  },
  watch: {
    $route() {
      this.handleRouteChange();
    },
  },


  created() {
    this.handleRouteChange();
    this.isLoading = false;
  },
  computed: {
    ...mapGetters(["getdevisMonoFiche"]),
    currentStepComponent() {
      return this.steps[this.step - 1].component;
    },
  },

  methods: {
    ...mapActions(["updatedevisMonoFiche", "getoneMonodevis", "setDevisMonoFiche"]),
    async handleRouteChange() {
      const id = this.$route.params.id;
      if (id) {

          await this.fetchDevisObject(id);
       
      }
    },
    handleStepChangePrv(newStep) {
      this.step = newStep;
    },
    async handleStepChange(newStep, loader) {
      this.isLoadingButton = loader;
      this.progress = true;

      try {
        const result = await this.updatedevis();
        if (!result) {
          this.Err = true;
          this.Valid = false;
          return;
        }
        this.Valid = true;
        this.Err = false;
        if (newStep > 8) {
          this.$router.push("/devis/list");
          return;
        }
        if (this.step === 7) {
          const id = this.$route.params.id;
          const fetchedDevisObject = await this.getoneMonodevis(id);
          fetchedDevisObject.data.devis.brouillon = 1;
        await this.setDevisMonoFiche(fetchedDevisObject);
          // this.handleRouteChange();
        }
        this.step = newStep;
      } catch (error) {
      
        this.Err = true;
      } finally {
        this.isLoadingButton = false;
        this.progress = false;
      }
    },

    async fetchDevisObject(id) {
      
        const fetchedDevisObject = await this.getoneMonodevis(id);
        fetchedDevisObject.data.devis.brouillon = 1;
        await this.setDevisMonoFiche(fetchedDevisObject);

        this.devisObjectLoaded = true;
        const produitIds =
          this.getdevisMonoFiche?.data.GroupeLigneDocument_produit.filter(
            (item) => item.produit_id
          ).map((item) => item.produit_id);
        this.produitIds = produitIds;

        if (this.fetchedDevisObject) {
          this.isLoading = false;
        }
     
    },
    async updatedevis() {
      
        const reponse = await this.updatedevisMonoFiche(
          this.getdevisMonoFiche.data.devis
        );
        return reponse;
      
    },
  },
};
</script>

<style lang="scss">
.v-stepper__step--complete .v-stepper__step__step {
  background-color: #24316d !important;
  border-color: #24316d !important;
  color: white !important;
}

.v-stepper__step--active .v-stepper__step__step {
  background-color: #24316d !important;
  border: 1px solid #24316d !important;
  color: white !important;
  box-shadow: 0 0 0 1px #fff inset;
  border-radius: 50%;
}

.v-stepper__step--complete + .v-divider {
  border-color: #24316d !important;
}

.v-divider {
  border-color: rgba(0, 0, 0, 0.12);
}

::before {
  text-decoration: inherit;
  vertical-align: inherit;
  color: white !important;
}
.v-stepper__header {
  height: 72px;
  align-items: stretch;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  box-shadow: none !important;
}
.theme--light.v-stepper .v-stepper__step--active .v-stepper__label {
  text-shadow: 0px 0px 0px black;
  color: #24316d !important;
}

.theme--light.v-stepper .v-stepper__step--complete .v-stepper__label {
  text-shadow: 0px 0px 0px black;
  color: #24316d !important;
}

.v-sheet.v-stepper:not(.v-sheet--outlined) {
  box-shadow: none !important;
}

.button-pre-style,
.button-suivant-style {
  background-color: #24316d;
  color: white;
  font-size: 13px;
  box-shadow: none !important;
}

.button-suivant-style:hover,
.button-suivant-style:focus,
.button-suivant-style:active {
  background-color: #24316d;
  color: white;
}

/* Responsive Styles */
@media only screen and (max-width: 1200px) {
  .v-stepper__step--complete .v-stepper__step__step,
  .v-stepper__step--active .v-stepper__step__step {
    font-size: 12px;
  }

  .stepper-buttons {
    flex-direction: column;
    align-items: center;
  }

  .button-pre-style,
  .button-suivant-style {
    font-size: 16px;
    padding: 8px 16px;
  }
}

@media only screen and (max-width: 530px) {
  .v-stepper__step--complete + .v-divider {
    border-color: white !important;
  }
  .v-stepper--alt-labels .v-stepper__step {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    flex-basis: 75px;
  }
  .theme--light.v-stepper .v-stepper__header .v-divider {
    border-color: #fff;
  }

  .v-divider {
    display: block;
    height: 0px;
    max-height: 0px;
    border: solid;
    border-width: thin 0 0 0;
    transition: inherit;
  }
  .v-stepper--alt-labels .v-stepper__step {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .v-stepper__step {
    align-items: center;
    display: flex;
    flex-direction: row;
  }
}
@media only screen and (max-width: 900px) {
  .v-stepper__step--complete .v-stepper__step__step,
  .v-stepper__step--active .v-stepper__step__step {
    font-size: 10px;
  }

  .stepper-buttons {
    flex-direction: column;
    align-items: center;
  }

  .button-pre-style,
  .button-suivant-style {
    font-size: 10px;
    padding: 6px 12px;
  }
}

@media only screen and (max-width: 600px) {
  .v-stepper__step--complete .v-stepper__step__step,
  .v-stepper__step--active .v-stepper__step__step {
    font-size: 8px;
  }

  .stepper-buttons {
    flex-direction: column;
    align-items: center;
  }

  .button-pre-style,
  .button-suivant-style {
    font-size: 8px;
    padding: 4px 8px;
  }
}

.stepper-content {
  padding: 0 10px;
}
</style>
