<template>
  <div>
    <div class="row my-4 mx-1 justify-content-center">
      <v-container v-if="progress" type="image" class="loding">
        <v-skeleton-loader type="image"></v-skeleton-loader>
      </v-container>
      <div v-else class="col-lg-8 col-sm-12 col-md-10 borderPointier">
        <div class="background-white">
          <div class="title">
            <div class="position">{{ $t("GENERALITY") }}</div>
          </div>
        </div>
        <form class="mt-4 mb-4">
        
          <b-form-group :label="$t('COMPANY_ID')" v-if="isSuperAdmin && getcreationEntreprise">
            <multiselect
              v-model="getdevisMonoFiche.data.devis.entreprise"
              :searchable="true"
              :close-on-select="true"
              :multiple="false"
              :options="getAllentreprises"
              label="lib"
              track-by="id"
                @input="updateEntrepriseSelection"
            >
              <template slot="noResult">
                {{ $t("NO_DATA_FOUND") }}
              </template>
            </multiselect>
         
          </b-form-group>
          <!-- Technical Visit Date Input -->
          <b-form-group :label="$t('DATE_VISITE_TECHNIQUE')">
            <b-form-input
              type="date"
              v-model="getdevisMonoFiche.data.devis.visite_technique"
              class="form-input custom-placeholder custom-input"
              @input="
                updateDevis(
                  'visite_technique',
                  getdevisMonoFiche.data.devis.visite_technique
                )
              "
              required
            ></b-form-input>
          </b-form-group>

          <!-- Start Date Input -->
          <b-form-group :label="$t('DATE_START_DEVIS')">
            <b-form-input
              type="date"
              v-model="getdevisMonoFiche.data.devis.debut_devis"
              required
              class="form-input custom-placeholder custom-input"
              @input="
                updateDevis(
                  'debut_devis',
                  getdevisMonoFiche.data.devis.debut_devis
                )
              "
            ></b-form-input>
          </b-form-group>

          <!-- End Date Input -->
          <b-form-group :label="$t('DATE_END_DEVIS')">
            <b-form-input
              type="date"
              v-model="getdevisMonoFiche.data.devis.fin_devis"
              required
              class="form-input custom-placeholder custom-input"
              @input="
                updateDevis('fin_devis', getdevisMonoFiche.data.devis.fin_devis)
              "
            ></b-form-input>
          </b-form-group>
        </form>
      </div>
    </div>
    <div class="d-flex justify-content-end mt-4">
      <b-button
        type="submit"
        @click="goToNextStep"
        class="button-suivant-style m-3"
      >
      {{$t("NEXT")}}
      <div class="loading ml-2" v-if="isLoading">
        <div class="spinner-border spinner-border-sm" role="status"></div>
      </div>
        <div class="loading ml-2" v-if="isLoadingButton">
          <div class="spinner-border spinner-border-sm" role="status"></div>
        </div>
      </b-button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data(){
    return{
      
      isLoading:false,
    }
  },
  props: {
    operation: {
      type: Boolean,
    
    },
    isLoadingButton: {
      type: Boolean,
      default: false,
    },
    progress: {
      type: Boolean,
      default: false,
    },
  },
  created() { 
    if (this.getdevisMonoFiche?.data && this.getdevisMonoFiche?.data?.devis) {
      this.initializeDateData();
      this.formatDates();
    }
    if(this.isSuperAdmin){
      this.all_entreprises({
          page: 1,
          per_page: 99999,
        });
    }
  },
  computed: {
    ...mapGetters(["getdevisMonoFiche","getOnlineUser", "getAllentreprises","getcreationEntreprise"]),
    isSuperAdmin() {
      return this.getOnlineUser.role == "user.super-admin";
    },
    operationStatus() {
      return this.operation ? 'L\'opération est activée.' : 'L\'opération est désactivée.';
    },
  },
 
  methods: {
    ...mapActions(["setDevisMonoFiche","all_entreprises",]),
    initializeDateData() {
      const devis = this.getdevisMonoFiche?.data?.devis;

      if (!devis.visite_technique) {
        devis.visite_technique = new Date().toISOString().slice(0, 10);
      }

      if (!devis.debut_devis) {
        devis.debut_devis = new Date().toISOString().slice(0, 10);
      }

      if (!devis.fin_devis) {
        const futureDate = new Date();
        futureDate.setDate(futureDate.getDate() + 30);
        devis.fin_devis = futureDate.toISOString().slice(0, 10);
      }
    },
    formatDateLocal(dateString) {
  if (!dateString) return ''; 
  const date = new Date(dateString);
  
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); 
  const day = date.getDate().toString().padStart(2, '0');
  
  return `${year}-${month}-${day}`; 
},
    formatDates() {
  if (this.getdevisMonoFiche?.data?.devis?.visite_technique) {
    this.getdevisMonoFiche.data.devis.visite_technique = this.formatDateLocal(
      this.getdevisMonoFiche?.data?.devis?.visite_technique
    );
  }

  if (this.getdevisMonoFiche?.data?.devis?.debut_devis) {
    this.getdevisMonoFiche.data.devis.debut_devis = this.formatDateLocal(
      this.getdevisMonoFiche?.data?.devis?.debut_devis
    );
  }

  if (this.getdevisMonoFiche?.data?.devis?.fin_devis) {
    this.getdevisMonoFiche.data.devis.fin_devis = this.formatDateLocal(
      this.getdevisMonoFiche?.data?.devis?.fin_devis
    );
  }
    },
    updateEntrepriseSelection(selectedEntreprise) {
    this.getdevisMonoFiche.data.devis.entreprise = selectedEntreprise;
    this.getdevisMonoFiche.data.devis.entreprise_id = selectedEntreprise.id;
    this.setDevisMonoFiche(this.getdevisMonoFiche);
  },
    updateDevis(field, value) {
      this.getdevisMonoFiche.data.devis[field] = value;
      this.setDevisMonoFiche(this.getdevisMonoFiche);
    },
    goToNextStep() {
      this.isLoading=true;
      this.getdevisMonoFiche.data.devis.montant_cee = 1000;
      this.getdevisMonoFiche.data.devis.brouillon = 1;
      this.setDevisMonoFiche(this.getdevisMonoFiche);
      this.isLoading=false;
      this.$emit("changeStep", 2, true);
    },
  },
};
</script>

<style scoped>
.borderPointier {
  border: 2px dashed #24316d;
  position: relative;
}
.custom-placeholder::placeholder {
  font-size: 13px;
}

.form-input {
  font-size: 13px;
  height: 41.1px !important;
}
.background-white {
  display: inline-block;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  position: absolute;
  top: -1rem;
  left: 0;
  right: 0;
  z-index: 1;
  text-align: center;
}
.small-spinner .spinner-border {
  width: 1rem;
  height: 1rem;
}
.title {
  color: #24316d;
  position: relative;
  z-index: 2;
  margin: 0;
}

.position {
  background-color: white;
  display: inline-block;
  padding: 0.05rem 2rem;
}
.custom-input {
  box-shadow: none;
}
</style>
