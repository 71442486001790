<template>
  <div class="">
    <div class="row mt-4">
      <!--section client-->
      <div class="col-md-6 col-lg-6 col-sm-12">
        <v-container v-if="progress" type="image" class="loding">
          <v-skeleton-loader type="image"></v-skeleton-loader>
          <v-skeleton-loader type="image"></v-skeleton-loader>
          <v-skeleton-loader type="image"></v-skeleton-loader>
          <v-skeleton-loader type="image"></v-skeleton-loader>
        </v-container>
        <form v-else @submit.prevent="addCLI">
          <div class="background-white mt-3">
            <div class="title">
              <div class="position">{{ $t("CLIENT") }}</div>
            </div>
          </div>
          <div class="center borderPointier mt-3 p-3">
            <b-form-group :label="$t('CUSTOMER')" class="style">
              <multiselect
                v-model="selectedClient"
                :searchable="true"
                :close-on-select="true"
                :multiple="false"
                :options="getAllclients"
                label="nom"
                track-by="id"
                :placeholder="$t('Select_agent')"
                class="form-input custom-placeholder custom-input"
              />
            </b-form-group>
            <b-form-group :label="$t('Type')">
              <b-form-select
                v-model="v$.clientData.type.$model"
                :options="optionsTypeClient"
                :state="validateState('type')"
                aria-describedby="type-feedback"
                 :disabled="!clientAdd || selected"
                @change="v$.$reset()"
              ></b-form-select>
              <error-handle
                :list="v$.clientData.type.$errors"
                id="type-feedback"
              ></error-handle>
              <div v-if="erreurlist.type" class="error-message">
                <ul v-if="Array.isArray(erreurlist.type)">
                  <span v-for="(e, index) in erreurlist.type" :key="index">
                    {{ e }}
                  </span>
                </ul>
                <span v-else>{{ erreurlist.type }}</span>
              </div>
            </b-form-group>
            <div v-if="clientData.type == 'type.particulier'">
              <div class="fieldset mt-3">
                <div class="legend mb-1">{{ $t("SURNAME") }}</div>
                <b-form-input
                  :state="validateState('prenom')"
                  oninput="this.value = this.value.toUpperCase()"
                  v-model="v$.clientData.prenom.$model"
                  :placeholder="$t('SURNAME')"
                  aria-describedby="prenom-feedback"
                  class="form-input custom-placeholder custom-input"
                >
                </b-form-input>
                <error-handle
                  :list="v$.clientData.prenom.$errors"
                  id="prenom-feedback"
                ></error-handle>
                <div v-if="erreurlist.prenom" class="error-message">
                  <ul v-if="Array.isArray(erreurlist.prenom)">
                    <span v-for="(e, index) in erreurlist.prenom" :key="index">
                      {{ e }}
                    </span>
                  </ul>
                  <span v-else>{{ erreurlist.prenom }}</span>
                </div>
              </div>

              <div class="fieldset">
                <div class="legend mb-1">{{ $t("NAME") }}</div>
                <b-form-input
                  v-model="v$.clientData.nom.$model"
                  :state="validateState('nom')"
                  :placeholder="$t('NAME')"
                  aria-describedby="nom-feedback"
                  oninput="this.value = this.value.toUpperCase()"
                  class="form-input custom-placeholder custom-input"
                  :class="{ 'form-control': false }"
                >
                </b-form-input>
                <error-handle
                  :list="v$.clientData.nom.$errors"
                  id="nom-feedback"
                ></error-handle>
                <div v-if="erreurlist.nom" class="error-message">
                  <ul v-if="Array.isArray(erreurlist.nom)">
                    <span v-for="(e, index) in erreurlist.nom" :key="index">
                      {{ e }}
                    </span>
                  </ul>
                  <span v-else>{{ erreurlist.nom }}</span>
                </div>
              </div>

              <div class="fieldset mt-3">
                <div class="legend mb-1">{{ $t("STREET") }}</div>
                <b-form-input
                  v-model="v$.clientData.rue.$model"
                  :state="validateState('rue')"
                  :placeholder="$t('STREET')"
                  aria-describedby="rue-feedback"
                  class="form-input custom-placeholder custom-input"
                ></b-form-input>
                <error-handle
                  :list="v$.clientData.rue.$errors"
                  id="rue-feedback"
                ></error-handle>
                <div v-if="erreurlist.rue" class="error-message">
                  <ul v-if="Array.isArray(erreurlist.rue)">
                    <span v-for="(e, index) in erreurlist.rue" :key="index">
                      {{ e }}
                    </span>
                  </ul>
                  <span v-else>{{ erreurlist.rue }}</span>
                </div>
              </div>

              <div class="row mt-3 adresse">
                <div class="col-lg-6 col-md-12 col-sm-12">
                  <div class="legend mb-1">{{ $t("POSTAL_CODE") }}</div>
                  <b-form-input
                    type="number"
                   @keydown="(event) => preventExceedingLength(event)"
                    @input="validatePostalCode"
                    v-model="v$.clientData.cp.$model"
                    :state="validateState('cp')"
                    :placeholder="$t('POSTAL_CODE')"
                    aria-describedby="cp-feedback"
                    maxlength="5"
                    max="99999"
                    class="form-input custom-placeholder custom-input"
                  ></b-form-input>
                  <error-handle
                    :list="v$.clientData.cp.$errors"
                    id="cp-feedback"
                  ></error-handle>
                  <div v-if="erreurlist.cp" class="error-message">
                    <ul v-if="Array.isArray(erreurlist.cp)">
                      <span v-for="(e, index) in erreurlist.cp" :key="index">
                        {{ e }}
                      </span>
                    </ul>
                    <span v-else>{{ erreurlist.cp }}</span>
                  </div>
                </div>

                <div class="col-lg-6 col-md-12 col-sm-12">
                  <div class="legend mb-1">{{ $t("CITY") }}</div>
                  <b-form-input
                    v-model="clientData.ville"
                    :placeholder="$t('CITY')"
                    aria-describedby="ville-feedback"
                    @keydown="preventNumberInput"
                    @input="
                      clientData.ville = clientData.ville.replace(
                        /[^a-zA-ZÀ-ÿ\s'-]/g,
                        ''
                      )
                    "
                    class="form-input custom-placeholder custom-input"
                  ></b-form-input>
                  <error-handle
                    :list="v$.clientData.ville.$errors"
                    id="ville-feedback"
                  ></error-handle>
                  <div v-if="erreurlist.ville" class="error-message">
                    <ul v-if="Array.isArray(erreurlist.ville)">
                      <span v-for="(e, index) in erreurlist.ville" :key="index">
                        {{ e }}
                      </span>
                    </ul>
                    <span v-else>{{ erreurlist.ville }}</span>
                  </div>
                </div>
              </div>

              <div class="row mt-3 adresse">
                <div class="col-lg-6 col-md-12 col-sm-12">
              <b-form-group :label="$t('ZONE')">
                <b-form-select
                  v-model="clientData.zone"
                  :options="optionsZone"
                  text-field="text"
                  value-field="value"
                  class="form-input custom-placeholder custom-input"
                >
                  <template #first>
                    <b-form-select-option :value="null" :disabled="selected"
                      >-- {{ $t("ZONE") }} --</b-form-select-option
                    >
                  </template>
                </b-form-select>
              </b-form-group>
             </div>

                <div class="col-lg-6 col-md-12 col-sm-12">
                  <b-form-group :label="$t('COUNTRY')">
                    <multiselect
                      v-model="clientData.pays"
                      :placeholder="$t('COUNTRY')"
                      :searchable="true"
                      :close-on-select="true"
                      :multiple="false"
                      :options="getAllcountries"
                      label="name"
                      track-by="id"
                      class="form-input custom-placeholder custom-input"
                    >
                      <template slot="noResult">
                        {{ $t("NO_DATA_FOUND") }}
                      </template>
                      <div v-if="erreurlist.pays" class="error-message">
                        <ul v-if="Array.isArray(erreurlist.pays)">
                          <span
                            v-for="(e, index) in erreurlist.pays"
                            :key="index"
                          >
                            {{ e }}
                          </span>
                        </ul>
                        <span v-else>{{ erreurlist.pays }}</span>
                      </div>
                      <div
                        v-if="v$.clientData.pays.$error"
                        class="error-message"
                      >
                        {{ $t("REQUIRED_FIELD") }}
                      </div>
                    </multiselect>
                  </b-form-group>
                </div>
              </div>
            </div>
            <div
              v-if="clientData.type == 'type.professionnel'"
              style="width: 100%"
            >
              <h5 class="titresociete">
                {{ $t("CONTACT_S") }}
              </h5>
              <b-form-group :label="$t('SOCIETY') + '*'">
                <b-form-input
                  v-model="v$.clientData.company.$model"
                  oninput="this.value = this.value.toUpperCase()"
                  :state="validateState('company')"
                  aria-describedby="company-feedback"
                ></b-form-input>
                <error-handle
                  :list="v$.clientData.company.$errors"
                  id="company-feedback"
                ></error-handle>
                <div v-if="erreurlist.company" class="error-message">
                  <ul v-if="Array.isArray(erreurlist.company)">
                    <span v-for="(e, index) in erreurlist.company" :key="index">
                      {{ e }}
                    </span>
                  </ul>
                  <span v-else>{{ erreurlist.company }}</span>
                </div>
              </b-form-group>

              <b-form-group :label="$t('STREET') + '*'" label-for="rue">
                <b-form-input
                  v-model="v$.clientData.rue.$model"
                  :state="validateState('rue')"
                  :placeholder="$t('STREET')"
                  aria-describedby="rue-feedback"
                  class="form-input custom-placeholder custom-input"
                ></b-form-input>
                <error-handle
                  :list="v$.clientData.rue.$errors"
                  id="rue-feedback"
                ></error-handle>
                <div v-if="erreurlist.rue" class="error-message">
                  <ul v-if="Array.isArray(erreurlist.rue)">
                    <span v-for="(e, index) in erreurlist.rue" :key="index">
                      {{ e }}
                    </span>
                  </ul>
                  <span v-else>{{ erreurlist.rue }}</span>
                </div>
              </b-form-group>
              <b-form-group :label="$t('POSTAL_CODE') + '*'" label-for="cp">
                <b-form-input
                  type="number"
                @keydown="(event) => preventExceedingLength(event)"
                  @input="validatePostalCode"
                  v-model="v$.clientData.cp.$model"
                  :state="validateState('cp')"
                  :placeholder="$t('POSTAL_CODE')"
                  aria-describedby="cp-feedback"
                  maxlength="5"
                  max="99999"
                  class="form-input custom-placeholder custom-input"
                ></b-form-input>
                <error-handle
                  :list="v$.clientData.cp.$errors"
                  id="cp-feedback"
                ></error-handle>
                <div v-if="erreurlist.cp" class="error-message">
                  <ul v-if="Array.isArray(erreurlist.cp)">
                    <span v-for="(e, index) in erreurlist.cp" :key="index">
                      {{ e }}
                    </span>
                  </ul>
                  <span v-else>{{ erreurlist.cp }}</span>
                </div>
              </b-form-group>
              <b-form-group :label="$t('CITY') + '*'" label-for="ville">
                <b-form-input
                  v-model="clientData.ville"
                  :placeholder="$t('CITY')"
                  aria-describedby="ville-feedback"
                  @keydown="preventNumberInput"
                  @input="
                    clientData.ville = clientData.ville.replace(
                      /[^a-zA-ZÀ-ÿ\s'-]/g,
                      ''
                    )
                  "
                  class="form-input custom-placeholder custom-input"
                ></b-form-input>
                <error-handle
                  :list="v$.clientData.ville.$errors"
                  id="ville-feedback"
                ></error-handle>
                <div v-if="erreurlist.ville" class="error-message">
                  <ul v-if="Array.isArray(erreurlist.ville)">
                    <span v-for="(e, index) in erreurlist.ville" :key="index">
                      {{ e }}
                    </span>
                  </ul>
                  <span v-else>{{ erreurlist.ville }}</span>
                </div>
              </b-form-group>
              <b-form-group :label="$t('COUNTRY')">
                <multiselect
                  v-model="clientData.pays"
                  :placeholder="$t('COUNTRY')"
                  :searchable="true"
                  :close-on-select="true"
                  :multiple="false"
                  :options="getAllcountries"
                  label="name"
                  track-by="id"
                  class="form-input custom-placeholder custom-input"
                >
                  <template slot="noResult">
                    {{ $t("NO_DATA_FOUND") }}
                  </template>
                  <div v-if="erreurlist.pays" class="error-message">
                    <ul v-if="Array.isArray(erreurlist.pays)">
                      <span v-for="(e, index) in erreurlist.pays" :key="index">
                        {{ e }}
                      </span>
                    </ul>
                    <span v-else>{{ erreurlist.pays }}</span>
                  </div>
                  <div v-if="v$.clientData.pays.$error" class="error-message">
                    {{ $t("REQUIRED_FIELD") }}
                  </div>
                </multiselect>
              </b-form-group>
              <b-form-group
                v-modal="this.clientData.company_tel"
                :label="$t('COMPANY_PHONE') + '*'"
              >
                <template>
                  <phone
                    :tel="clientData.company_tel"
                    @phoneNumber="formatNumberE($event)"
                    class="form-input custom-placeholder custom-input"
                  ></phone>
                </template>
                <div v-if="erreurlist.company_tel" class="error-message">
                  <ul v-if="Array.isArray(erreurlist.company_tel)">
                    <span
                      v-for="(e, index) in erreurlist.company_tel"
                      :key="index"
                    >
                      {{ e }}
                    </span>
                  </ul>
                  <span v-else>{{ erreurlist.company_tel }}</span>
                </div>

                <div
                  v-if="clientData.company_tel && !phoneValid"
                  class="error-message"
                >
                  <!-- {{ $t("ERROR_PHONE") }} -->
                </div>
              </b-form-group>

              <b-form-group
                :label="$t('COMPANY_EMAIL') + '*'"
                label-for="email"
              >
                <b-form-input
                  v-model="v$.clientData.company_email.$model"
                  required
                  type="email"
                  :state="validateState('company_email')"
                  :disabled="!clientAdd || selected"
                  aria-describedby="company_email-feedback"
                ></b-form-input>
                <error-handle
                  :list="v$.clientData.company_email.$errors"
                  id="company_email-feedback"
                ></error-handle>
                <div v-if="erreurlist.company_email" class="error-message">
                  <ul v-if="Array.isArray(erreurlist.company_email)">
                    <span
                      v-for="(e, index) in erreurlist.company_email"
                      :key="index"
                    >
                      {{ e }}
                    </span>
                  </ul>
                  <span v-else>{{ erreurlist.company_email }}</span>
                </div>
              </b-form-group>

              <h5 class="titresociete">{{ $t("COMPANY_INTER") }}</h5>

              <div class="fieldset mt-3">
                <div class="legend mb-1">{{ $t("SURNAME") }}</div>
                <b-form-input
                  :state="validateState('prenom')"
                  oninput="this.value = this.value.toUpperCase()"
                  v-model="v$.clientData.prenom.$model"
                  :placeholder="$t('SURNAME')"
                  aria-describedby="prenom-feedback"
                  class="form-input custom-placeholder custom-input"
                >
                </b-form-input>
                <error-handle
                  :list="v$.clientData.prenom.$errors"
                  id="prenom-feedback"
                ></error-handle>
                <div v-if="erreurlist.prenom" class="error-message">
                  <ul v-if="Array.isArray(erreurlist.prenom)">
                    <span v-for="(e, index) in erreurlist.prenom" :key="index">
                      {{ e }}
                    </span>
                  </ul>
                  <span v-else>{{ erreurlist.prenom }}</span>
                </div>
              </div>

              <div class="fieldset">
                <div class="legend mb-1">{{ $t("NAME") }}</div>
                <b-form-input
                  v-model="v$.clientData.nom.$model"
                  :state="validateState('nom')"
                  :placeholder="$t('NAME')"
                  aria-describedby="nom-feedback"
                  oninput="this.value = this.value.toUpperCase()"
                  class="form-input custom-placeholder custom-input"
                  :class="{ 'form-control': false }"
                >
                </b-form-input>
                <error-handle
                  :list="v$.clientData.nom.$errors"
                  id="nom-feedback"
                ></error-handle>
                <div v-if="erreurlist.nom" class="error-message">
                  <ul v-if="Array.isArray(erreurlist.nom)">
                    <span v-for="(e, index) in erreurlist.nom" :key="index">
                      {{ e }}
                    </span>
                  </ul>
                  <span v-else>{{ erreurlist.nom }}</span>
                </div>
              </div>

              <b-form-group :label="$t('Poste') + '*'">
                <b-form-input
                  v-model="v$.clientData.poste.$model"
                  oninput="this.value = this.value.toUpperCase()"
                  :state="validateState('poste')"
                  aria-describedby="poste-feedback"
                ></b-form-input>
                <error-handle
                  :list="v$.clientData.poste.$errors"
                  id="poste-feedback"
                ></error-handle>
              </b-form-group>
            </div>
          </div>
        </form>
      </div>

      <!--section client-->
      <div class="col-md-6 col-lg-6 col-sm-12">
        <v-container v-if="progress" type="image" class="loding">
          <v-skeleton-loader type="image"></v-skeleton-loader>
        </v-container>
        <div v-else class="background-white mt-3">
          <div class="title">
            <div class="position">{{ $t("CLIENT") }}</div>
          </div>
        </div>
        <form @submit.prevent="addCLI">
          <v-container v-if="progress" type="image" class="loding">
            <v-skeleton-loader type="image"></v-skeleton-loader>
          </v-container>
          <div v-else class="borderPointier mt-3 pt-3 px-3">
            <div v-if="clientData.type == 'type.professionnel'">
              <b-form-group
                :label="$t('PHONE')"
                v-modal="this.clientData.tel"
                label-for="telephone"
                class="mb-1 mt-3"
              >
                <template>
                  <phone
                    :tel="clientData.tel"
                    @phoneNumber="formatNumber($event)"
                    class="form-input custom-placeholder custom-input"
                  ></phone>
                </template>
                <div v-if="erreurlist.tel" class="error-message">
                  <ul v-if="Array.isArray(erreurlist.tel)">
                    <span v-for="(e, index) in erreurlist.tel" :key="index">
                      {{ e }}
                    </span>
                  </ul>
                  <span v-else>{{ erreurlist.tel }}</span>
                </div>
                <div v-if="v$.clientData.tel.$error" class="error-message">
                  {{ $t("REQUIRED_FIELD") }}
                </div>
                <div
                  v-if="clientData.tel && resultValid == false"
                  class="error-message"
                >
                  {{ $t("ERROR_PHONE") }}
                </div>
              </b-form-group>

              <b-form-group :label="$t('EMAIL') + '*'" label-for="email">
                <b-form-input
                  type="email"
                  :state="validateState('email')"
                  v-model="v$.clientData.email.$model"
                  :placeholder="$t('CLIENT_EMAIL')"
                  aria-describedby="email-feedback"
                  :disabled="!clientAdd || selected"
                  class="form-input custom-placeholder custom-input"
                ></b-form-input>
                <error-handle
                  :list="v$.clientData.email.$errors"
                  id="email-feedback"
                ></error-handle>
                <div v-if="erreurlist.email" class="error-message">
                  <ul v-if="Array.isArray(erreurlist.email)">
                    <span v-for="(e, index) in erreurlist.email" :key="index">
                      {{ e }}
                    </span>
                  </ul>
                  <span v-else>{{ erreurlist.email }}</span>
                </div>
              </b-form-group>
              <h5 class="titresociete">{{ $t("COMPANY_BANK") }}</h5>

              <b-form-group :label="$t('ACCOUNT_OWNER')">
                <b-form-input
                  v-model="v$.clientData.titulaire_compte.$model"
                  :state="validateState('titulaire_compte')"
                  aria-describedby="titulaire_compte-feedback"
                ></b-form-input>
                <error-handle
                  :list="v$.clientData.titulaire_compte.$errors"
                  id="titulaire_compte-feedback"
                ></error-handle>
                <div v-if="erreurlist.titulaire_compte" class="error-message">
                  <ul v-if="Array.isArray(erreurlist.titulaire_compte)">
                    <span
                      v-for="(e, index) in erreurlist.titulaire_compte"
                      :key="index"
                    >
                      {{ e }}
                    </span>
                  </ul>
                  <span v-else>{{ erreurlist.titulaire_compte }}</span>
                </div>
              </b-form-group>

              <b-form-group label="IBAN" label-for="IBAN">
                <b-form-input
                  v-model="v$.clientData.IBAN.$model"
                  :state="validateState('IBAN')"
                  aria-describedby="IBAN-feedback"
                ></b-form-input>
                <error-handle
                  :list="v$.clientData.IBAN.$errors"
                  id="IBAN-feedback"
                ></error-handle>
                <div v-if="erreurlist.IBAN" class="error-message">
                  <ul v-if="Array.isArray(erreurlist.IBAN)">
                    <span v-for="(e, index) in erreurlist.IBAN" :key="index">
                      {{ e }}
                    </span>
                  </ul>
                  <span v-else>{{ erreurlist.IBAN }}</span>
                </div>
              </b-form-group>

              <b-form-group label="SWIFT_BIC" label-for="SWIFT_BIC">
                <b-form-input
                  v-model="v$.clientData.SWIFT_BIC.$model"
                  :state="validateState('SWIFT_BIC')"
                  aria-describedby="SWIFT_BIC-feedback"
                  oninput="this.value = this.value.toUpperCase()"
                ></b-form-input>
                <error-handle
                  :list="v$.clientData.SWIFT_BIC.$errors"
                  id="SWIFT_BIC-feedback"
                ></error-handle>
                <div v-if="erreurlist.SWIFT_BIC" class="error-message">
                  <ul v-if="Array.isArray(erreurlist.SWIFT_BIC)">
                    <span
                      v-for="(e, index) in erreurlist.SWIFT_BIC"
                      :key="index"
                    >
                      {{ e }}
                    </span>
                  </ul>
                  <span v-else>{{ erreurlist.SWIFT_BIC }}</span>
                </div>
              </b-form-group>

              <b-form-group :label="$t('revenu_impo')" label-for="revenu_avis_imposition">
                <b-form-input
                  v-model="v$.clientData.revenu_avis_imposition"
                 type="number"
                  @input="validateRevenu_avis_imposition"
                 min="0"
                  aria-describedby="revenu_avis_imposition-feedback"
                  oninput="this.value = this.value.toUpperCase()"
                ></b-form-input>
                <!-- <error-handle
                  :list="v$.clientData.revenu_avis_imposition.$errors"
                  id="revenu_avis_imposition-feedback"
                ></error-handle> -->
                <!-- <div v-if="erreurlist.revenu_avis_imposition" class="error-message">
                  <ul v-if="Array.isArray(erreurlist.revenu_avis_imposition)">
                    <span
                      v-for="(e, index) in erreurlist.revenu_avis_imposition"
                      :key="index"
                    >
                      {{ e }}
                    </span>
                  </ul>
                  <span v-else>{{ erreurlist.revenu_avis_imposition }}</span>
                </div> -->
              </b-form-group>
              <b-form-group label="forme_juridique" label-for="forme_juridique">
                <b-form-input
                  v-model="v$.clientData.forme_juridique.$model"
                  :state="validateState('forme_juridique')"
                  aria-describedby="forme_juridique-feedback"
                ></b-form-input>
                <error-handle
                  :list="v$.clientData.forme_juridique.$errors"
                  id="forme_juridique-feedback"
                ></error-handle>
                <div v-if="erreurlist.forme_juridique" class="error-message">
                  <ul v-if="Array.isArray(erreurlist.forme_juridique)">
                    <span v-for="(e, index) in erreurlist.forme_juridique" :key="index">
                      {{ e }}
                    </span>
                  </ul>
                  <span v-else>{{ erreurlist.forme_juridique }}</span>
                </div>
              </b-form-group>
              <b-form-group label="ape" label-for="ape">
                <b-form-input
                  v-model="v$.clientData.ape.$model"
                  :state="validateState('ape')"
                  aria-describedby="ape-feedback"
                ></b-form-input>
                <error-handle
                  :list="v$.clientData.ape.$errors"
                  id="ape-feedback"
                ></error-handle>
                <div v-if="erreurlist.ape" class="error-message">
                  <ul v-if="Array.isArray(erreurlist.ape)">
                    <span v-for="(e, index) in erreurlist.ape" :key="index">
                      {{ e }}
                    </span>
                  </ul>
                  <span v-else>{{ erreurlist.ape }}</span>
                </div>
              </b-form-group>
              <b-form-group label="naf" label-for="naf">
                <b-form-input
                  v-model="v$.clientData.naf.$model"
                  :state="validateState('naf')"
                  aria-describedby="naf-feedback"
                ></b-form-input>
                <error-handle
                  :list="v$.clientData.naf.$errors"
                  id="ape-feedback"
                ></error-handle>
                <div v-if="erreurlist.naf" class="error-message">
                  <ul v-if="Array.isArray(erreurlist.naf)">
                    <span v-for="(e, index) in erreurlist.naf" :key="index">
                      {{ e }}
                    </span>
                  </ul>
                  <span v-else>{{ erreurlist.naf }}</span>
                </div>
              </b-form-group>
            <hr />

              <b-form-group :label="$t('COMMENTAIRE')" label-for="commentaire">
                <b-form-textarea
                  v-model="clientData.commentaire"
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>
                <div v-if="erreurlist.commentaire" class="error-message">
                  <ul v-if="Array.isArray(erreurlist.commentaire)">
                    <span
                      v-for="(e, index) in erreurlist.commentaire"
                      :key="index"
                    >
                      {{ e }}
                    </span>
                  </ul>
                  <span v-else>{{ erreurlist.commentaire }}</span>
                </div>
              </b-form-group>
            </div>
            <div v-if="clientData.type == 'type.particulier'">
              <b-form-group :label="$t('précaire')" class="mt-2">
                <b-form-select
                  class="form-input custom-placeholder custom-input"
                  v-model="clientData.precarite"
                  :placeholder="$t('précaire')"
                  :options="optionsprécaire"
                  text-field="text"
                  value-field="value"
                >
                  <template #first>
                    <b-form-select-option :value="null" disabled
                      >-- {{ $t("précaire") }} --</b-form-select-option
                    >
                  </template>
                </b-form-select>
              </b-form-group>
              <div class="fieldset">
                <div class="legend mb-1">{{ $t("EMAIL") }}</div>
                <b-form-input
                  type="email"
                  :state="validateState('email')"
                  v-model="v$.clientData.email.$model"
                  :placeholder="$t('CLIENT_EMAIL')"
                  aria-describedby="email-feedback"
                  :disabled="!clientAdd || selected"
                  class="form-input custom-placeholder custom-input"
                ></b-form-input>
                <error-handle
                  :list="v$.clientData.email.$errors"
                  id="email-feedback"
                ></error-handle>
                <div v-if="erreurlist.email" class="error-message">
                  <ul v-if="Array.isArray(erreurlist.email)">
                    <span v-for="(e, index) in erreurlist.email" :key="index">
                      {{ e }}
                    </span>
                  </ul>
                  <span v-else>{{ erreurlist.email }}</span>
                </div>
              </div>
              <div class="fieldset mb-4">
                <b-form-group
                  :label="$t('PHONE')"
                  v-modal="this.clientData.tel"
                  label-for="telephone"
                  class="mb-1 mt-3"
                >
                  <template>
                    <phone
                      :tel="clientData.tel"
                      @phoneNumber="formatNumber($event)"
                      class="form-input custom-placeholder custom-input"
                    ></phone>
                  </template>
                  <div v-if="erreurlist.tel" class="error-message">
                    <ul v-if="Array.isArray(erreurlist.tel)">
                      <span v-for="(e, index) in erreurlist.tel" :key="index">
                        {{ e }}
                      </span>
                    </ul>
                    <span v-else>{{ erreurlist.tel }}</span>
                  </div>
                  <div v-if="v$.clientData.tel.$error" class="error-message">
                    {{ $t("REQUIRED_FIELD") }}
                  </div>
                  <div
                    v-if="clientData.tel && resultValid == false"
                    class="error-message"
                  >
                    {{ $t("ERROR_PHONE") }}
                  </div>
                </b-form-group>
              </div>
              <b-form-group :label="$t('revenu_impo')" label-for="revenu_avis_imposition">
                <b-form-input
                  v-model="v$.clientData.revenu_avis_imposition"
                   @input="validateRevenu_avis_imposition"
                   type="number"
                   min="0"
                  aria-describedby="revenu_avis_imposition-feedback"
                  oninput="this.value = this.value.toUpperCase()"
                ></b-form-input>
                <!-- <error-handle
                  :list="v$.clientData.revenu_avis_imposition.$errors"
                  id="revenu_avis_imposition-feedback"
                ></error-handle> -->
                <!-- <div v-if="erreurlist.revenu_avis_imposition" class="error-message">
                  <ul v-if="Array.isArray(erreurlist.revenu_avis_imposition)">
                    <span
                      v-for="(e, index) in erreurlist.revenu_avis_imposition"
                      :key="index"
                    >
                      {{ e }}
                    </span>
                  </ul>
                  <span v-else>{{ erreurlist.revenu_avis_imposition }}</span>
                </div> -->
              </b-form-group>
            </div>
          
          </div>
        </form>
        <form>
          <v-container v-if="progress" type="image" class="loding">
            <v-skeleton-loader type="image"></v-skeleton-loader>
          </v-container>
          <!--section logement-->
          <div v-else class="borderPointier mt-3 pt-3 px-3">
            <div class="background-white">
              <div class="title">
                <div class="position">{{ $t("HOUSING") }}</div>
              </div>
            </div>
            <b-form-group :label="$t('TYPE_CHAUFFAGE')" class="mt-2">
              <b-form-select
                class="form-input custom-placeholder custom-input"
                v-model="logementData.type_chauffage"
                :placeholder="$t('TYPE_CHAUFFAGE')"
                :options="optionstypechauffage"
                text-field="text"
                value-field="value"
              >
                <template #first>
                  <b-form-select-option :value="null" disabled
                    >-- {{ $t("TYPE_CHAUFFAGE") }} --</b-form-select-option
                  >
                </template>
              </b-form-select>
            </b-form-group>
            <b-form-group :label="$t('TYPE_LOGEMENT')">
              <b-form-select
                :placeholder="$t('TYPE_LOGEMENT')"
                class="form-input custom-placeholder custom-input"
                v-model="logementData.type"
                :options="optionstypelogement"
                text-field="text"
                value-field="value"
              >
                <template #first>
                  <b-form-select-option :value="null" disabled
                    >-- {{ $t("TYPE_LOGEMENT") }} --</b-form-select-option
                  >
                </template>
              </b-form-select>
            </b-form-group>

            <b-row>
              <b-col md="6">
                <b-form-group :label="$t('SENIORITY')">
                  <b-form-select
                    :placeholder="$t('SENIORITY')"
                    class="form-input custom-placeholder custom-input"
                    v-model="logementData.ancienter"
                    :options="optionsAncientelogement"
                    text-field="text"
                    value-field="value"
                  >
                    <template #first>
                      <b-form-select-option :value="null" disabled
                        >-- {{ $t("SENIORITY") }} --</b-form-select-option
                      >
                    </template>
                  </b-form-select>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group :label="$t('SURFACE')">
                  <b-form-input
                    type="number"
                    min="0"
                    @keydown="(event) => preventExceedingLength(event)"
                      @input="validateSurface"
                    :placeholder="$t('SURFACE')"
                    v-model="logementData.surface"
                    required
                    class="form-input custom-placeholder custom-input"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
        
            
            
            <b-form-group :label="$t('CADASTRAL_PARCEL')" class="mb-4">
              <b-form-input
                type="text"
                :placeholder="$t('CADASTRAL_PARCEL')"
                v-model="logementData.parcelle_cadastrale"
                required
                class="form-input custom-placeholder custom-input"
              ></b-form-input>
            </b-form-group>
            <b-form-group :label="$t('equipement')">
              <b-form-select
                :placeholder="$t('equipement')"
                class="form-input custom-placeholder custom-input"
                v-model="logementData.equipement_deposee"
                :options="optionstypeEquipement_deposee"
                text-field="text"
                value-field="value"
              >
                <template #first>
                  <b-form-select-option :value="null" disabled
                    >-- {{ $t("equipement") }} --</b-form-select-option
                  >
                </template>
              </b-form-select>
            </b-form-group>
            <b-form-group :label="$t('Type_de_combustible')">
              <b-form-select
                :placeholder="$t('Type_de_combustible')"
                class="form-input custom-placeholder custom-input"
                v-model="logementData.type_energie_equipement_deposee"
                :options="optionsType_de_combustible"
                text-field="text"
                value-field="value"
              >
                <template #first>
                  <b-form-select-option :value="null" disabled
                    >-- {{ $t("Type_de_combustible") }} --</b-form-select-option
                  >
                </template>
              </b-form-select>
            </b-form-group>
          
          </div>
        </form>
      </div>
    </div>
    <div class="d-flex justify-content-end mt-4">
      <b-button @click="goToPrevStep" class="button-pre-style m-3">
        {{ $t("PREVIOUS") }}
      </b-button>
      <b-button
        type="submit"
        @click="goToNextStep"
        class="button-suivant-style m-3"
      >
        {{ $t("NEXT") }}
        <div class="loading ml-2" v-if="isLoading">
          <div class="spinner-border spinner-border-sm" role="status"></div>
        </div>
        <div class="loading ml-2" v-if="isLoadingButton">
          <div class="spinner-border spinner-border-sm" role="status"></div>
        </div>
      </b-button>
    </div>
  </div>
</template>
<script>
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import phone from "@/components/ui/phone.vue";
import { useVuelidate } from "@vuelidate/core";
import { mapGetters, mapActions } from "vuex";
import {
  required,
  email,
  maxLength,
  minLength,
  requiredIf,
  alphaNum,
  numeric,
} from "@vuelidate/validators";
import errorHandle from "@/components/ui/errorHandle.vue";
export default {
  components: {
    phone,
    errorHandle,
  },
  props: {
    isLoadingButton: {
      type: Boolean,
      default: false,
    },
    progress: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return { v$: useVuelidate({ $lazy: true, $autoDirty: true }) };
  },
  data() {
    return {
      isLoading: false,
      erreurlist: {
        nom: null,
        prenom: null,
        email: null,
        rue: null,
        cp: null,
        ville: null,
        tel: null,
        type: null,
        zone:null,
        pays: null,
        SWIFT_BIC: null,
        forme_juridique:null,
        ape:null,
        naf:null,
        // revenu_avis_imposition:null,
        IBAN: null,
        titulaire_compte: null,
        commentaire: null,
        company: null,
        company_email: null,
        company_tel: null,
     
        precarite: null,
        type_chauffage: null,
        type_logement: null,
      },
      phoneValid: null,
      resultValid: null,
      errorS: null,
      inputSearchValue: null,
      selected: false,
      clientData: {
        type: "type.particulier",
        nom: null,
        prenom: null,
        email: null,
        rue: null,
        zone:null,
        cp: null,
        ville: null,
        tel: {
          phone_number: null,
          phone_iso_code: null,
        },
        pays: null,
        company: null,
        company_email: null,
        company_tel: {
          phone_number: null,
          phone_iso_code: null,
        },
        SWIFT_BIC: null,
        forme_juridique:null,
        ape:null,
        naf:null,
        revenu_avis_imposition:null,
        IBAN: null,
        titulaire_compte: null,
        poste: null,
        commentaire: null,
        
        precarite: null,
        type_chauffage: null,
        type_logement: null,
      },
      logementData: null,
      optionsTypeClient: [
        { value: "type.particulier", text: this.$t("PARTICULAR") },
        { value: "type.professionnel", text: this.$t("PROFESSIONAL") },
      ],
      optionsZone: [
        { value: "h1", text: "H1" },
        { value: "h2", text: "H2" },
        { value: "h3", text: "H3" },
      ],
      optionstypeEquipement_deposee:[
      { value: "Chaudière", text: this.$t("chaudière") },
        { value: "Cheminée", text: this.$t("cheminée") },
        { value: "Pôle", text: "pôle" },
      ],
      optionsType_de_combustible:[
      { value: "Granulé", text: this.$t("granulé") },
      { value: "Fioul", text: this.$t("Fuel") },
      { value: "Bois", text: this.$t("Bois") },
      { value: "Gaz", text: this.$t("Gaz") },
      ],
      optionstypechauffage: [
        { value: "Combustible/bois", text: this.$t("Combustible/Bois") },
        { value: "Electrique", text: this.$t("Electrique") },
        { value: "Gaz", text: this.$t("Gaz") },
        { value: "GPL", text: this.$t("GPL") },
        { value: "Fioul", text: this.$t("Fuel") },
        { value: "Bois", text: this.$t("Bois") },
        { value: "ENR/PAC", text: "ENR/PAC" },
      ],
      optionsprécaire: [
        { value: "Modeste", text: this.$t("Modeste") },
        { value: "Intermédiaires", text: this.$t("Intermédiaires") },
        { value: "tres_modeste", text: this.$t("Tmodeste") },
        { value: "supérieurs", text: this.$t("Supérieurs") },
      ],
      optionstypelogement: [
        { value: "Maison_individuelle", text: this.$t("Maison individuelle") },
        { value: "Appartement", text: this.$t("Appartement") },
        { value: "Collectif", text: this.$t("Collectif") },
      ],
      optionsAncientelogement: [
       
        { value: "2", text: this.$t("Moins_de_2_ans") },
        { value: "3", text: this.$t("Plus_de_2_ans_et_moins_de_15_ans") },
        { value: "15", text: this.$t("Plus_de_15_ans") },
      ],
      search: "",
      disabled: false,
      form: {},
      clientAdd: false,
      logementAdd: false,
    };
  },
  created() {
    this.all_countries({ page: this.page, perPage: 100000 });
    this.all_clients({
      search: "",
      entreprise_id:this.getdevisMonoFiche.data.devis.entreprise_id,
    });


    if (this.getdevisMonoFiche && this.getdevisMonoFiche?.data) {
      this.clientData = this.initializeClientData();
      this.logementData = this.initializeLogementData();
    }
  },
  computed: {
    ...mapGetters([
  
      "getAllcountries",
      "getAllclients",
      "getdevisMonoFiche",
      "getOnlineUser",
    ]),
    isSuperAdmin() {
      return this.getOnlineUser.role == "user.super-admin";
    },
    selectedClient: {
      get() {
        if (typeof this.clientData === "object" && this.clientData !== null) {
          return this.clientData;
        } else {
          return this.getAllclients.find(
            (option) => option.id === this.clientData
          );
        }
      },
      set(value) {
        if (value) {
          this.clientData = value;
          this.clientData.tel = {
            phone_number: this.clientData.phone?.phone_number || null,
            phone_iso_code: this.clientData.phone?.iso_code || null,
          };
          this.clientData.company_tel = {
            phone_number: this.clientData.company_phone?.phone_number || null,
            phone_iso_code: this.clientData.company_phone?.iso_code || null,
          };
          this.selected = true;
        } else if (!value) {
          (this.clientData = {
            nom: "",
            prenom: "",
            rue: "",
            cp: "",
            ville: "",
            id: null,
            zone:null,
            precarite: null,
            type: null,
            email: "",
            pays: "",
            phone: "",
            tel: {
              phone_number: "",
              phone_iso_code: "",
            },
          }),
            (this.clientData.type = "type.particulier");
          this.selected = false;
        }
      },
    },
  },

  watch: {
    inputSearchValue(newValue) {
      if (newValue.trim() === "") {
        this.selected = false;
      }
    },

    getdevisMonoFiche: {
      handler(newValue) {
        if (newValue && newValue.data) {
          this.clientData = this.initializeClientData();
          this.logementData = this.initializeLogementData();
          this.clientAdd = newValue.data.devis.client === null;
          this.logementAdd = newValue.data.devis.logement === null;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  validations() {
    return {
      clientData: {
        type: { required },
        nom: { required },
        prenom: { required },
        email: { required, email, maxLength: maxLength(50) },
        rue: { required, maxLength: maxLength(100) },
        cp: {
          required,
          numeric,
          minLength: minLength(4),
          maxLength: maxLength(5),
        },
        ville: { required, maxLength: maxLength(100) },
        tel: {
          phone_number: { required },
        },
        pays: { required },
      
        company: {
          requiredIf: requiredIf(this.isProfessionel),
          maxLength: maxLength(100),
        },
        company_email: {
          email,
          requiredIf: requiredIf(this.isProfessionel),
          maxLength: maxLength(50),
        },
        company_tel: {
          phone_number: { requiredIf: requiredIf(this.isProfessionel) },
        },
        poste: {
          requiredIf: requiredIf(this.isProfessionel),
          maxLength: maxLength(40),
        },
        // revenu_avis_imposition:{
        //   required
        // },
        forme_juridique:{
          requiredIf: requiredIf(this.isProfessionel),
        },
        ape:{
          requiredIf: requiredIf(this.isProfessionel),
        },
        naf:{
          requiredIf: requiredIf(this.isProfessionel),
        },
        SWIFT_BIC: {
          minLength: minLength(8),
          maxLength: maxLength(11),
          alphaNum,
        },
        IBAN: { maxLength: maxLength(34), alphaNum },
        titulaire_compte: { maxLength: maxLength(40) },
      },
      logementData: {},
    };
  },

  methods: {
    ...mapActions([
      "editLogement",
      "edit_client",
      "storeLogement",
      "all_clients",
      "pays",
      "store_clients",
      "client",
      "store_client",
      "all_countries",
    
      "setDevisMonoFiche",
    ]),
    preventExceedingLength(event, allowedPattern = /[0-9]/, additionalAllowedKeys = ['Backspace', 'Delete']) {
  const disallowedSequences = ['-', '+', '--', '++', '//'];
  if (disallowedSequences.includes(event.key)) {
    event.preventDefault();
    return;
  }
  if (!allowedPattern.test(event.key) && !additionalAllowedKeys.includes(event.key)) {
    event.preventDefault();
  }
},
    
    validatePostalCode() {
      let value = this.clientData.cp;

      value = value.replace(/[^0-9]/g, "");

      this.clientData.cp = value;

      this.validationFeedback =
        value.length === 5 ? "" : "Please enter exactly 5 digits";
    },
    validateSurface() {
  let value = this.logementData.surface;

  
  value = value.replace(/[^0-9]/g, "");

  
  this.logementData.surface = value;
},

    validateRevenu_avis_imposition() {
      let value = this.clientData.revenu_avis_imposition;

      value = value.replace(/[^0-9]/g, "");

      this.clientData.revenu_avis_imposition = value;

      
    },
    
    preventNumberInput(event) {
      const key = event.key;

      if (key >= "0" && key <= "9") {
        event.preventDefault();
      }
    },
    initializeLogementData() {
      const logement = this.getdevisMonoFiche?.data?.devis?.logement || {};
      return {
        type_energie_equipement_deposee:logement?.type_energie_equipement_deposee||null,
        equipement_deposee:logement?.equipement_deposee||null,
       
        type_chauffage: logement.type_chauffage || null,
        type: logement.type || null,
        ancienter: logement.ancienter || "",
        surface: logement.surface || "",
        parcelle_cadastrale: logement.parcelle_cadastrale || "",
      };
    },
    initializeClientData() {
      const client = this.getdevisMonoFiche?.data?.devis.client || {};

      return {
        nom: client?.nom || "",
        prenom: client?.prenom || "",
        rue: client?.rue || "",
        zone:client?.zone||null,
        cp: client?.cp || "",
        ville: client?.ville || "",
        id: client.id || null,
        precarite: client?.precarite || null,
        type: client?.type || "type.particulier",
        email: client?.email || null,
        pays: client?.pays || null,
        phone: client?.phone || null,
        company_phone: client?.company_phone,
     
        company_phone_iso_code:client?.company_tel?.phone_iso_code || null,
        
        tel: {
          phone_number: client.phone?.phone_number || null,
          phone_iso_code: client.phone?.iso_code || null,
        },
        company_tel: {
          phone_number: client.company_phone?.phone_number || null,
          phone_iso_code: client.company_phone?.iso_code || null,
        },

        company: client.company || null,
        company_email: client.company_email || null,
        revenu_avis_imposition:client.revenu_avis_imposition ||null,
        SWIFT_BIC: client.SWIFT_BIC || null,
        forme_juridique:client.forme_juridique|| null,
        ape:client.ape||null,
        naf:client.naf||null,
        IBAN: client.IBAN || null,
        titulaire_compte: client.titulaire_compte || null,
        poste: client.poste || null,
        commentaire: client.commentaire || null,
      };
    },
    removeSpaces(phoneNumber) {
    return phoneNumber.replace(/\s+/g, '');
},
    formatNumber(event) {
      if (event && event.number && event.country) {
        this.clientData.tel = {
          phone_number: event.number,
          phone_iso_code: event.country.iso2,
        };
        this.resultValid = event.isValid;
      }
    },
    formatNumberE(event) {
      this.clientData.company_tel = {
        phone_number: event.number,
        phone_iso_code: event.country.iso2,
      };
      this.phoneValid = event.isValid;
    },
    validateState(name) {
      const fieldValidation = this.v$.clientData[name];
      if (fieldValidation) {
        const { $dirty, $error } = fieldValidation;
        return $dirty ? !$error : null;
      }
      return null;
    },
    async addCLI() {
      if (this.selected) {
        const client = { ...this.clientData,
          phone_iso_code:this.clientData.tel.iso_code,
          company_phone_iso_code:this.clientData?.company_tel?.phone_iso_code,
        }

          if(this.clientData.type==="type.particulier"){
              client.phone_number=this.clientData.tel.phone_number
             }else{
              const phone=this.cleanPhoneNumber(this.clientData.tel.phone_number)
              client.phone_number=this.removeSpaces(phone)
                      
            const company_phone=this.cleanPhoneNumber(this.clientData?.company_tel?.phone_number)
            client.company_phone_number=this.removeSpaces(company_phone)
             }
          await this.edit_client({ data: client });
          const resclient= await this.client(this.clientData?.id)
         
        
 
        const loges = { ...this.logementData, client_id: this.clientData.id };

        const res = await this.storeLogement(loges);
        if (
          this.getdevisMonoFiche &&
          this.getdevisMonoFiche.data &&
          this.getdevisMonoFiche.data.devis
        ) {
          this.getdevisMonoFiche.data.devis.logement_id = res.data.id;

          this.getdevisMonoFiche.data.devis.client_id = this.clientData.id;
      
        }
        this.getdevisMonoFiche.data.devis.logement = {
          id: res.data.id,
   
          equipement_deposee:this.logementData.equipement_deposee,
          type_energie_equipement_deposee:this.logementData.type_energie_equipement_deposee,
          type: this.logementData.type,
          ancienter: this.logementData.ancienter,
          surface: this.logementData.surface,
          parcelle_cadastrale: this.logementData.parcelle_cadastrale,
          type_chauffage: this.logementData.type_chauffage,
        };
        this.getdevisMonoFiche.data.devis.client = {
          id: this.clientData.id,
          company_phone_iso_code:client?.company_tel?.phone_iso_code,
    
          precarite: this.clientData?.precarite,
          pays: this.clientData?.pays,
          nom: this.clientData?.nom,
          prenom: this.clientData?.prenom,
          rue: this.clientData?.rue,
          zone:this.clientData?.zone,
          type: this.clientData?.type,
          ville: this.clientData?.ville,
          phone: resclient?.data.phone,
          company_phone: resclient?.data.company_phone,
          cp: this.clientData?.cp,
          email: this.clientData?.email,
          company: this.clientData?.company,
          company_email: this.clientData?.company_email,
          SWIFT_BIC: this.clientData?.SWIFT_BIC,
          naf:this.clientData?.naf,
          ape:this.clientData?.ape,
          forme_juridique:this.client?.forme_juridique,
          revenu_avis_imposition:this.clientData?.revenu_avis_imposition,
          IBAN: this.clientData?.IBAN,
          titulaire_compte: this.clientData?.titulaire_compte,
          poste: this.clientData?.poste,
          commentaire: this.clientData?.commentaire,
          company_tel:resclient.company_tel,
        };
      
        this.setDevisMonoFiche(this.getdevisMonoFiche);
        return { logement: res, client: this.clientData };
      } else {
        if (this.clientAdd) {
          const data = {
            ...this.clientData,
            pays: this.clientData.pays.iso_code,
            phone_iso_code: this.clientData.tel.phone_iso_code,
            phone_number: this.clientData.tel.phone_number,
       
            tel: {
              phone_iso_code: this.clientData.tel.phone_iso_code,
              phone_number: this.clientData.tel.phone_number,
            },
           
          };
          if(this.clientData.type==='type.particulier'){
            data.company_tel= {
              phone_number: this.clientData.company_tel.phone_number,
              phone_iso_code: this.clientData.company_tel.phone_iso_code,
            }
          }
          if (this.clientData.tel && this.clientData.tel.phone_number) {
            var num = this.clientData.tel.phone_number.indexOf(" ");
            data.phone_number = this.clientData.tel.phone_number
              .slice(num + 1)
              .split(" ")
              .join("");
            data.tel = data.phone_number;
            data.phone_iso_code = this.clientData.tel.phone_iso_code;
          }

          if (
            this.clientData.company_tel &&
            this.clientData.company_tel.phone_number
          ) {
            var numE = this.clientData.company_tel.phone_number.indexOf(" ");
            data.company_phone_number = this.clientData.company_tel.phone_number
              .slice(numE + 1)
              .split(" ")
              .join("");

            data.company_tel = data.company_phone_number;
            data.company_phone_iso_code =
              this.clientData?.company_tel?.phone_iso_code;
          }

        data.entreprise_id=this.getdevisMonoFiche.data.devis.entreprise_id;

        
          data.precarite = this.clientData.precarite;
          const isFormCorrect = await this.v$.$validate();

          if (!isFormCorrect) return;
          this.erreurlist = {
            nom: null,
            prenom: null,
            email: null,
            rue: null,
            zone:null,
            cp: null,
            ville: null,
            tel: null,
            type: null,
          
            pays: null,
            SWIFT_BIC: null,
            forme_juridique:null,
            ape:null,
            naf:null,
            // revenu_avis_imposition:null,
            IBAN: null,
            titulaire_compte: null,
            commentaire: null,
            company: null,
            company_email: null,
            company_tel: null,
          
            precarite: null,
            type_chauffage: null,
            type_logement: null,
          };

          this.errorS = null;

          if (this.resultValid == false) return;
          if (this.clientData.company_tel && this.phoneValid == false) return;
          try {
          
            if (data.type === "type.particulier") {

  delete data.IBAN;
  delete data.SWIFT_BIC;
  delete data.forme_juridique;
  delete data.naf;
  delete data.ape;
  delete data.commentaire;
  delete data.company;
  delete data.company_email;
  delete data.company_phone;
  delete data.company_phone_iso_code;
  delete data.company_tel;
  delete data.poste;
}
            const response = await this.store_clients(data);
            this.errorS = null;
            const loges = { ...this.logementData, client_id: response.id };

            const res = await this.storeLogement(loges);

            this.getdevisMonoFiche.data.devis.logement_id = res.data.id;
            this.getdevisMonoFiche.data.devis.client_id = response.id;
       this.getdevisMonoFiche.data.devis.logement = {
              id: res.data.id,
         
              equipement_deposee:this.logementData.equipement_deposee,
              type_energie_equipement_deposee:this.logementData.type_energie_equipement_deposee,
              type: this.logementData.type,
              ancienter: this.logementData.ancienter,
              surface: this.logementData.surface,
              parcelle_cadastrale: this.logementData.parcelle_cadastrale,
              type_chauffage: this.logementData.type_chauffage,
            };
            console.log('rrrrrrrrrr',this.clientData)
            this.getdevisMonoFiche.data.devis.client = {
              id: response.id,
              precarite: this.clientData.precarite,
              pays: this.clientData.pays,
              nom: this.clientData.nom,
              prenom: this.clientData.prenom,
              rue: this.clientData.rue,
              zone:this.clientData.zone,
              type: this.clientData.type,
              ville: this.clientData.ville,
              tel: this.clientData.tel,
              phone: this.clientData.phone,
              company_phone: this.clientData.company_phone,
              cp: this.clientData.cp,
              email: this.clientData.email,
              company: this.clientData.company,
              company_email: this.clientData.company_email,
              SWIFT_BIC: this.clientData.SWIFT_BIC,
              forme_juridique:this.clientData.forme_juridique,
              naf:this.clientData.naf,
              ape:this.clientData.ape,
              revenu_avis_imposition:this.clientData.revenu_avis_imposition,
              IBAN: this.clientData.IBAN,
              titulaire_compte: this.clientData.titulaire_compte,
              poste: this.clientData.poste,
              commentaire: this.clientData.commentaire,
              company_tel: this.clientData.company_tel,
            };
            console.log(this.getdevisMonoFiche.data.devis.client)
           console.log('getdevisMonoFiche',this.getdevisMonoFiche)
            this.setDevisMonoFiche(this.getdevisMonoFiche);
            return { logement: res, client: response };
          } catch (err) {
            if (this.isObject(err)) {
              for (const [key, value] of Object.entries(err)) {
                if (!this.erreurlist[key]) {
                  this.erreurlist[key] = value;
                }
              }
            } else {
              this.errorS = err;
            }
          }
        }
      }
    },
    cleanPhoneNumber(phoneNumber) {
    return phoneNumber.replace(/^\+\d+\s*/, '');
},

    
isObject(obj) {
      return Object.prototype.toString.call(obj) === "[object Object]";
    },
    async edit() {
      if (this.clientAdd) {
        await this.addCLI();
        const client= await this.client(this.getdevisMonoFiche.data.devis.client_id)
        this.getdevisMonoFiche.data.devis.client = {
              id: this.getdevisMonoFiche.data.devis.client_id,
              precarite: client.data.precarite,
              pays: client.data.pays,
              nom: client.data.nom,
              prenom: client.data.prenom,
              rue: client.data.rue,
              zone:client.data.zone,
              type:client.data.type,
              ville: client.data.ville,
              tel: client.data.tel,
              phone: client.data.phone,
              company_phone: client.data.company_phone,
              cp: client.data.cp,
              email: client.data.email,
              company: client.data.company,
              company_email: client.data.company_email,
              SWIFT_BIC: client.data.SWIFT_BIC,
              forme_juridique:client.data.forme_juridique,
              naf:client.data.naf,
              ape:client.data.ape,
              revenu_avis_imposition: client.data.revenu_avis_imposition,
              IBAN: client.data.IBAN,
              titulaire_compte:client.data.titulaire_compte,
              poste: client.data.poste,
              commentaire: client.data.commentaire,
              company_tel: client.data.company_tel,
            };
           
      } else {
        if (
          this.getdevisMonoFiche.data.devis?.client &&
          this.getdevisMonoFiche.data.devis?.client?.id !== this.clientData.id
        ) {
          if (!this.clientData) {
            return;
          }
          this.getdevisMonoFiche.data.devis.client_id = this.clientData.id;
          this.getdevisMonoFiche.data.devis.same_travaux_address === 0;
          const client = { ...this.clientData, id: this.clientData.id ,
             phone_iso_code:this.clientData.tel.iso_code,
             company_phone_iso_code:this.clientData?.company_tel?.phone_iso_code};
             if(this.clientData.type==="type.particulier"){
              client.phone_number=this.clientData.tel.phone_number
             }else{
              const phone=this.cleanPhoneNumber(this.clientData.tel.phone_number)
              client.phone_number=this.removeSpaces(phone)
                      
            const company_phone=this.cleanPhoneNumber(this.clientData?.company_tel?.phone_number)
            client.company_phone_number=this.removeSpaces(company_phone)
             }

        await this.edit_client({ data: client });
          const resclient= await this.client(this.clientData?.id)
         
          const loges = { ...this.logementData, client_id: this.clientData.id };
          const logementresult = await this.storeLogement(loges);
          this.getdevisMonoFiche.data.devis.logement = {
            id: logementresult.data.id,
          
            equipement_deposee:this.logementData.equipement_deposee,
            type_energie_equipement_deposee:this.logementData.type_energie_equipement_deposee,
            type: this.logementData.type,
            ancienter: this.logementData.ancienter,
            surface: this.logementData.surface,
            parcelle_cadastrale: this.logementData.parcelle_cadastrale,
            type_chauffage: this.logementData.type_chauffage,
          };
          this.getdevisMonoFiche.data.devis.logement_id =
            logementresult.data.id;
          this.getdevisMonoFiche.data.devis.client = {
            id: this.clientData.id,
          
            precarite: this.clientData?.precarite,
            pays: this.clientData?.pays,
            nom: this.clientData?.nom,
            prenom: this.clientData?.prenom,
            rue: this.clientData?.rue,
            zone:this.clientData?.zone,
            type: this.clientData?.type,
        
            ville: this.clientData?.ville,
            phone: resclient?.data.phone,
            company_phone: resclient?.data.company_phone,
            cp: this.clientData?.cp,
            email: this.clientData?.email,

            company: this.clientData?.company,
            company_email: this.clientData?.company_email,
            SWIFT_BIC: this.clientData?.SWIFT_BIC,
            forme_juridique:this.clientData?.forme_juridique,
            ape:this.clientData?.ape,
            naf:this.clientData?.naf,
            revenu_avis_imposition:this.clientData?.revenu_avis_imposition,
            IBAN: this.clientData?.IBAN,
            titulaire_compte: this.clientData?.titulaire_compte,
            poste: this.clientData?.poste,
            commentaire: this.clientData?.commentaire,
            company_tel: resclient?.data.company_tel,
          };
       
          this.setDevisMonoFiche(this.getdevisMonoFiche);
        } else {
          const client = {
            ...this.clientData,
            id: this.getdevisMonoFiche.data.devis.client.id,
            phone_iso_code:this.clientData.tel.iso_code,
            company_phone_iso_code:this.clientData?.company_tel?.phone_iso_code,
        
          };
          if(this.clientData.type==="type.particulier"){
            const phone=this.cleanPhoneNumber(this.clientData.tel.phone_number)
            client.phone_number=this.removeSpaces(phone)
             }else{
              const phone=this.cleanPhoneNumber(this.clientData.tel.phone_number)
              client.phone_number=this.removeSpaces(phone)
                      
            const company_phone=this.cleanPhoneNumber(this.clientData?.company_tel?.phone_number)
            client.company_phone_number=this.removeSpaces(company_phone)
             }
      
       await this.edit_client({ data: client });
       const resclient= await this.client(this.clientData?.id)
         

          const log = {
            ...this.logementData,
            client_id:this.clientData?.id,
            id: this.getdevisMonoFiche.data.devis.logement.id,
          };
          await this.editLogement(log);
          this.getdevisMonoFiche.data.devis.logement = {
            id: this.getdevisMonoFiche.data.devis.logement.id,
            type: this.logementData.type,
        
            equipement_deposee:this.logementData.equipement_deposee,
            type_energie_equipement_deposee:this.logementData.type_energie_equipement_deposee,
            ancienter: this.logementData.ancienter,
            surface: this.logementData.surface,
            parcelle_cadastrale: this.logementData.parcelle_cadastrale,
            type_chauffage: this.logementData.type_chauffage,
          };
          this.getdevisMonoFiche.data.devis.client = {
            id: client.id,
            precarite: this.clientData?.precarite,
            pays: this.clientData?.pays,
            nom: this.clientData?.nom,
            prenom: this.clientData?.prenom,
            rue: this.clientData?.rue,
            zone:this.clientData?.zone,
            type: this.clientData?.type,
            ville: this.clientData?.ville,
            phone: resclient?.data.phone,
            company_phone: resclient?.data.company_phone,
            cp: this.clientData?.cp,
            email: this.clientData?.email,
        
            company: this.clientData?.company,
            company_email: this.clientData?.company_email,
            SWIFT_BIC: this.clientData?.SWIFT_BIC,
            forme_juridique:this.clientData?.forme_juridique,
            naf:this.clientData?.naf,
            ape:this.clientData?.ape,
            revenu_avis_imposition:this.clientData?.revenu_avis_imposition,
            IBAN: this.clientData?.IBAN,
            titulaire_compte: this.clientData?.titulaire_compte,
            poste: this.clientData?.poste,
            commentaire: this.clientData?.commentaire,
            company_tel: resclient?.data.company_tel,
          };
        
          this.setDevisMonoFiche(this.getdevisMonoFiche);
        }
      }
    },

    selectclient(client) {
      this.clientData = { ...client };
      this.getdevisMonoFiche.data.devis.client = { ...client };
      this.selected = true;
      this.disabled = true;
    },
    async goToNextStep() {
      this.isLoading = true;
      if (this.clientData.pays == null) {
        this.isLoading = false;
        this.$emit("changeStep", 3, true);
      }
      this.isLoading = false;
      await this.edit();

      this.$emit("changeStep", 3, true);
    },
    goToPrevStep() {
      this.$emit("changeStepPrev", 1);
    },
  },
};
</script>
<style scoped>
.custom-placeholder::placeholder {
  font-size: 13px;
}

.form-input {
  font-size: 13px;
  height: 41.1px !important;
}
.borderPointier {
  border: 2px dashed #24316d;
  position: relative;
}
.input-style {
  margin-bottom: 1rem;
  border-radius: 4px;
  border: 1px solid #eaebec;
  padding: 0.5rem 0.75rem;
  background-color: #fff;
}

.background-white {
  display: inline-block;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  position: absolute;
  top: -1rem;
  left: 0;
  right: 0;
  z-index: 1;
  text-align: center;
}
.title {
  color: #24316d;
  position: relative;
  z-index: 2;
  margin: 0;
}

.position {
  background-color: white;
  display: inline-block;
  padding: 0.05rem 2rem;
}
.form-control {
  box-shadow: none;
}
.custom-input {
  box-shadow: none;
}
.col-md-12 {
  padding: 0px 15px 0px 15px !important;
}
::v-deep .multiselect__tags {
  border: 1px solid #ced4da !important;
  font-size: 13px !important;
}
::v-deep .multiselect__input,
.multiselect__single {
  font-size: 13px !important;
}
::v-deep .multiselect__placeholder {
  color: #6c757d;
  display: inline-block;
  margin-bottom: 10px;
  padding-top: 2px;
}
::v-deep .multiselect__single {
  font-size: 13px !important;
}
.legend {
  font-size: 16px;
}
.small-spinner .spinner-border {
  width: 1rem;
  height: 1rem;
}
</style>
